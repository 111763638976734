@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap-sprockets";
@import "custom-bootstrap";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
  font-family: $droid-sans;
}

a,
a:hover,
a:active {
  color: #ea4036;
  text-decoration: none;
}

input {
  border:1px solid #b0b0b0;
  padding:3px 5px 4px;
  color:#979797;
}

// Background colors
%bg-blue {
  background-color: #3AB4C1;
  color: #FFF;
}

%bg-red {
  background-color: #EF2B31;
  color: #FFF;
}

%striped-bg {
  background: url('../images/stripe.png') repeat;
  color: #fff;
}

// Text colors
.red {
  color: #EF2B31;
}

.blue {
  color: #3ab4c1;
}

// Logo
.logo {
  font-size: 22px;
  font-weight: bold;
}

// Section
section.section {
  padding: 40px 0;
}

//// Buttons
.btn-red {
  @include button-variant(#FFF, #EF2B31, #EF2B31);
}

#wrapper-all {
  display: block;
  width: 100%;
  position: absolute;
  min-height: 100%;
}

#wrapper-content {
  display: block;
  width: 100%;
  margin-bottom: 124px;
}

/***** TOP *****/

#top {
  @extend %striped-bg;
  height: 47px;
}

/***** NAVIGATION *****/

.navbar-intro .navbar-brand,
.navbar-intro .navbar-nav > li > a {
  text-transform: uppercase;
}

/***** BANNER *****/
.jumbotron.intro {
  @extend %bg-blue;
  padding: 70px 0 124px;
}

.jumbotron h1.heading {
  font-size: 40px;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.jumbotron h2.pickup {
  margin: 0;
}

.jumbotron p {
  font-size: 14px;
}

/***** AUTH FORM *****/
.auth {
  margin-top: 10px;
}

.auth input[type="text"],
.auth input[type="email"],
.auth input[type="password"],
.auth input[type="submit"] {
  width: 100%;
  border-radius: 5px;
  height: 40px;
  padding: 0 10px;
}

.auth-extra {
  display: block;
  font-size: 12px;
  margin-bottom: 15px;
}

/***** ERRORS *****/
.error-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/***** SECTION *****/
.section h3 {
  font-size: 20px;
  font-weight: bold;
}

.section .title {
  margin: 0 0 40px 0;
}

/***** USP'S *****/
.usp {
  margin: 0 0 20px 0;
  overflow: hidden;
}

.usp-icon {
  width: 55px;
  height: 55px;
  border-radius: 100px;
  display: block;
  background-color: #3ab4c1;
  background-repeat: no-repeat;
  background-size: 100%;
  float: left;
  margin: 0 20px 20px 0;
}

.usp-icon.book {
  background-image: url('../images/icons/book.png');
}

.usp-icon.schedule {
  background-image: url('../images/icons/schedule.png');
}

.usp-icon.list {
  background-image: url('../images/icons/list.png');
}

.usp .content {
  float: left;
  width: 75%;
}

.usp .content h3 {
  font-size: 14px;
  margin: 0 0 10px 0;
  @extend .blue;
}

/***** STATS *****/
.stats {
  text-align: center;
  margin: 0 0 30px 0;
  overflow: hidden;
}

.stats h2 {
  font-size: 35px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.stats span {
  font-size: 12px;
}

/***** BOTTOM *****/
#footer {
  @extend %bg-blue;
}

#bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  @extend %striped-bg;
  height: 124px;
}